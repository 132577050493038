<template>
  <div>
    <v-toolbar tile flat elevation="1">
      <v-btn @click="$router.go(-1)" class="no-active" icon>
        <v-icon>$vuetify.icons.arrow_left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title>Техническая поддержка</v-toolbar-title>
    </v-toolbar>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" md="8">
          <v-card class="elevation-0">
            <v-form v-model="valid" ref="form">
              <v-container>
              <v-row align="center">
                <v-col cols="12" sm="6" class="pb-0">
                  <v-text-field
                    v-model="name"
                    label="Ваше имя"
                    prepend-icon="$vuetify.icons.account"
                    :rules="nameRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="pb-0">
                  <v-text-field
                    v-model="email"
                    label="E-mail"
                    prepend-icon="$vuetify.icons.gmail"
                    required
                    :rules="emailRules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="12">
                  <v-select
                    v-if="supportTypes && supportTypes.length"
                    v-model="supportType"
                    :items="supportTypes"
                    item-text="title"
                    item-value="id"
                    label="Тип вопроса"
                  ></v-select>
             
                  <v-text-field
                    v-model="topic"
                    label="Тема"
                  ></v-text-field>
              
                  <v-textarea
                    name="inputBody"
                    label="Сообщение"
                    v-model="body"
                    :rules="bodyRules"
                  ></v-textarea>
              
                   <v-btn class="mt-2" dark color="primary" @click="preSubmit">Отправить</v-btn>
                </v-col>
              </v-row>
              </v-container>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog v-model="dialogSubmit" class="text-center" max-width="400">
        <v-card>
          <v-card-title class="headline justify-center">Вы уверены?</v-card-title>

          <v-card-text class="text-center">Что Email <b>{{ email }}</b> заполнен правильно? Имеено на него придет ответ.</v-card-text>

          <v-card-actions class="justify-space-around">
            <v-btn color="red darken-1" text @click="submit">Да</v-btn>

            <v-btn color="green darken-1" text @click="dialogSubmit = false">Нет</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  data: () => ({
    valid: true,
    email: "",
    name: "",
    topic: "",
    body: "",
    dialogSubmit:false,
    supportType: 1,
    supportTypes: null,
    // support_types: [
    //   { id: 1, title: "Технические вопросы по работе с сайтом" },
    //   { id: 2, title: "Проблема с оплатой" },
    //   { id: 3, title: "Предложение" },
    //   { id: 4, title: "Вопросы рекламы" },
    //   { id: 5, title: "Жалоба на книгу" },
    //   { id: 6, title: "Жалоба на пользователя" },
    //   { id: 7, title: "Бизнес-запрос" },
    //   { id: 7, title: "Партнерская программа" },
    // ],
    nameRules: [
      (v) => !!v || "Поле обязательно",
      (v) => (v && v.length >= 3) || "Минимум 3 символа",
    ],
    emailRules: [
      (v) => !!v || "Поле обязательно",
      (v) => /.+@.+\..+/.test(v) || "Не верно введен E-mail",
    ],
    bodyRules: [
      (v) => !!v || 'Поле обязательно',
      (v) =>
        (!!v === v.length >= 10) === v.length <= 400 ||
        "Минимум 10 символов, максимум 400 символов",
    ],
  }),
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    updateData() {
      if (this.currentUser) {
      this.email = this.currentUser.email;
      this.name = this.currentUser.public_name;
      }
      if (this.siteSettings) {
        if(this.siteSettings && this.siteSettings.support_types && this.siteSettings.support_types.length > 0){
          this.supportTypes = this.siteSettings.support_types;
        }
      }
    },
    submit() {
      
      event.preventDefault();
      this.dialogSubmit = false;
      if (!this.validate()) return;
      let app = this;
      this.$store
        .dispatch("addSupport", {
          body: app.body,
          name: app.name,
          email: app.email,
          support_type: app.supportType,
          topic: app.topic,
        })
        .then((response) => {
          if (response.data.success == 1) {
            app.clear();
          }
        })
        .catch(() => {});
    },
    preSubmit() {
      event.preventDefault();
      if (!this.validate()) return;
      this.dialogSubmit = true;
    },
    clear(){
      this.topic = "";
      this.body = "";
      this.supportType = 1;
    }
    // getSupportTitle(){
    //   if(this.supportTypes){
    //     let result = this.supportTypes.find(item => item.id == this.supportType);
    //     return result.title;
    //   }
    //   return null;
    // }
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      siteSettings: "siteSettings",
    }),
  },
  watch: {
    currentUser() {
      this.updateData();     
    },
    siteSettings(){
      this.updateData();  
    }
  },
  created() {
    // this.$store.dispatch('getNotifications');
  },
  mounted(){
    this.updateData();
  }
};
</script>
